import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Form, Dropdown, DropdownButton, Badge, Col, Accordion, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUsers, faEye, faUser, faSearch, faBusinessTime, faIndustry, faFileExcel, faArrowRight, faFileImport, faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { colors } from '../../../tools/constants';
import { convert2Base64, pnint } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import moment from 'moment/moment';


export default function Import() {

    const [step1, setStep1] = React.useState(true);
    const [step2, setStep2] = React.useState(false);
    const [step3, setStep3] = React.useState(false);
    const [step4, setStep4] = React.useState(false);
    const [step5, setStep5] = React.useState(false);

    const [showLoading, setShowLoading] = React.useState(false);
    const handleCloseLoadingModal = () => { setShowLoading(false); };
    const handleShowLoadingModal = () => {
        setShowLoading(true);
    };

    const [step1File, setStep1File] = React.useState(null);
    const handleStep1File = async (event) => {
        const file = event.target.files[0]
        setStep1File(file)
    }

    const [step2File, setStep2File] = React.useState(null);
    const handleStep2File = async (event) => {
        const file = event.target.files[0]
        setStep2File(file)
    }

    const [brands, setBrands] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [providers, setProviders] = React.useState([]);
    const [selectedProvider, setSelectedProvider] = React.useState("");
    const [fileProducts, setFileProducts] = React.useState(null);
    const [fileStock, setFileStock] = React.useState(null);

    const PAGE_TITLE = "Importar";
    const PAGE_ICON = faFileImport;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `4-Tech - ${PAGE_TITLE}`;

        getProviders();

    }, []);

    const handleProvider = (event) => {
        for (let p of providers) {
            if (p.id == event.target.value) {
                setSelectedProvider(p)
            }
        }
    };

    const getProviders = async function () {
        const req = await get(`/provider/all`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setProviders(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const showNextStep = () => {
        if (step1) {
            setStep1(false);
            setStep2(true);
        } else if (step2) {
            setStep2(false);
            setStep3(true);
        } else if (step3) {
            setStep3(false);
            setStep4(true);
        } else if (step4) {
            setStep4(false);
            setStep5(true);
        }
    }

    const nextStep = () => {
        if (step1) {
            sendStep1Data();
        } else if (step2) {
            showNextStep();
        } else if (step3) {
            sendStep2Data();
        } else if (step4) {
            sendStep4Data()
        }
    }

    const sendStep1Data = async function () {
        let file = null;
        let ext;

        if (step1File != null) {
            file = await convert2Base64(step1File);
            ext = step1File.name.split('.');
            ext = ext[ext.length - 1];
        }

        const body = {
            file: file,
            extension: ext
        }

        const req = await post(`/provider/excel/processgbp`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            showNextStep();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const sendStep2Data = async function () {
        handleShowLoadingModal()
        const file = await convert2Base64(step2File);
        const ext = step2File.name.split('.')

        const body = {
            provider: selectedProvider.name,
            file: file,
            extension: ext[ext.length - 1]
        }

        const req = await post(`/provider/excel/processproviderfile`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseLoadingModal()
            console.log("PRODUCTS")
            console.log(res)
            setProducts(res.products)
            setBrands(res.brands)
            showNextStep();
        } else if (req.status === 403) {
            logout();
        } else if (req.status === 400) {
            handleCloseLoadingModal();
            alert(res.message);
        } else {
            alert(res.message);
        }
    }

    const sendStep4Data = async function () {
        let selectedProducts = [];

        for (let p of products) {
            try {
                if (document.getElementById(`checkProduct-${p.codep}`).checked == true) {
                    selectedProducts.push(p)
                }
            } catch (e) {

            }
        }

        const body = {
            products: selectedProducts
        }

        console.log(body)

        const req = await post(`/provider/excel/exporttogbp`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            setFileProducts(res.products);
            setFileStock(res.stock);
            showNextStep();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const selectAllByBrand = (select, brand) => {
        for (let p of products) {
            if (p.brand == brand) {
                document.getElementById(`checkProduct-${p.codep}`).checked = select;
            }
        }
    }

    const downloadProductsFile = () => {
        triggerBase64Download(fileProducts, `Precios_${selectedProvider.name.replaceAll(".", "")}_${moment().format("YYYYMMDDHHmm")}`)
    }

    const downloadStockFile = () => {
        triggerBase64Download(fileStock, `Stock_${selectedProvider.name.replaceAll(".", "")}_${moment().format("YYYYMMDDHHmm")}`)
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />


            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={6} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={6} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                <Link to={'/inicio'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} /><span className='me-3'>Volver al menú</span>
                                </Link>
                                {step1 ? "Paso 1 | Archivo GBP" : ""}
                                {step2 ? "Paso 2 | Proveedor" : ""}
                                {step3 ? "Paso 3 | Archivo del proveedor" : ""}
                                {step4 ? "Paso 4 | Vista previa" : ""}
                                {step5 ? "Paso 5 | Descarga de archivos" : ""}
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                                {step4 ? <Button onClick={nextStep}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Descargar vista previa</Button> : ""}
                            </Col>
                        </Row>

                        <Container style={{ display: (step1 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Cargá el Excel de artículos descargado de GBP</p>
                                    <p className='m-0 pt-4'><Form.Control accept='.xlsx, .xls' onChange={(event) => handleStep1File(event)} type="file" /></p>
                                    <p className='m-0 pt-4'>Si no seleccionás un archivo, se mantendrá el último Excel de GBP procesado</p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>

                        <Container style={{ display: (step2 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faIndustry} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Seleccioná un proveedor</p>
                                    <p className='m-0 pt-4'>
                                        <Form.Select className='' id="selectStep2Provider" onChange={(e) => handleProvider(e)}>
                                            <option key={0} value={0}>Seleccionar...</option>
                                            {providers.map((p, i) => (
                                                <option key={i} id={i} value={p.id}>{p.friendlyName}</option>
                                            ))}
                                        </Form.Select>
                                    </p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>

                        <Container style={{ display: (step3 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Cargá el archivo brindado por {selectedProvider.friendlyName}</p>
                                    <p className='m-0 pt-4'><Form.Control accept='.xlsx, .xls' onChange={(event) => handleStep2File(event)} type="file" /></p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>

                        <Container style={{ display: (step4 == false ? 'none' : '') }}>
                            <Row>
                                <Col className='pt-3 pb-5'>
                                    {brands.map((b, i) => (
                                        <Accordion className='p-0 mt-2'>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <div>
                                                        <p className='m-0'>{b}</p>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row style={{ display: 'inline-block' }}>
                                                        <Button style={{ width: 200 }} onClick={() => selectAllByBrand(true, b)}>Seleccionar todo</Button>
                                                        <Button style={{ width: 200 }} onClick={() => selectAllByBrand(false, b)} className='ms-2'>Deseleccionar todo</Button>
                                                        <span><Badge bg='success' className=''><span className='m-1'> </span></Badge> Producto correcto | <Badge bg='warning' className=''><span className='m-1'> </span></Badge> Sin stock | <Badge bg='danger' className=''><span className='m-1'> </span></Badge> Producto no cargado en GBP | <Badge bg='primary' className=''><span className='m-1'> </span></Badge> Producto no existente en proveedor</span>
                                                    </Row>
                                                    <div className='pt-3'>
                                                        <div>
                                                            <Row className='mt-1 p-2 mb-2' style={{ background: '#E7F1FF', borderRadius: 8 }}>
                                                                <Col className='align-center-vertically-v2' xs={1}>

                                                                </Col>
                                                                <Col className='align-center-vertically-v2' xs={2}>
                                                                    Código
                                                                </Col>
                                                                <Col className='align-center-vertically-v2' xs={5}>
                                                                    Producto
                                                                </Col>
                                                                <Col className='align-center-vertically-v2' xs={1}>
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        Stock
                                                                    </div>
                                                                </Col>
                                                                <Col className='align-center-vertically-v2' xs={2}>
                                                                    Precio
                                                                </Col>
                                                                <Col className='align-center-vertically-v2' xs={1}>


                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {products.map((p, i) => (
                                                            <div>
                                                                {p.brand.toUpperCase() == b.toUpperCase() ?
                                                                    <div>
                                                                        <Row className='mt-1 p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                                            <Col className='align-center-vertically-v2' xs={1}>
                                                                                {p.status == 0 ? <Badge bg='danger' className=''><span className='m-1'> </span></Badge> : <div></div>}
                                                                                {p.status == 1 ? <Badge bg='warning' className=''><span className='m-1'> </span></Badge> : <div></div>}
                                                                                {p.status == 2 ? <Badge bg='success' className=''><span className='m-1'> </span></Badge> : <div></div>}
                                                                                {p.status == 3 ? <Badge bg='primary' className=''><span className='m-1'> </span></Badge> : <div></div>}
                                                                            </Col>
                                                                            <Col className='align-center-vertically-v2' xs={2}>
                                                                                {p.codep}
                                                                            </Col>
                                                                            <Col className='align-center-vertically-v2' xs={5}>
                                                                                {p.name}
                                                                            </Col>
                                                                            <Col className='align-center-vertically-v2' xs={1}>
                                                                                <div style={{ textAlign: 'center' }}>
                                                                                    {p.stock}
                                                                                </div>
                                                                            </Col>
                                                                            <Col className='align-center-vertically-v2' xs={2}>
                                                                                {pn(p.price)} {p.currency}
                                                                            </Col>
                                                                            <Col className='align-center-vertically-v2' xs={1}>
                                                                                <Form.Check
                                                                                    type='checkbox'
                                                                                    id={`checkProduct-${p.codep}`}
                                                                                    defaultChecked={p.select}
                                                                                />

                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    :
                                                                    <div></div>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>


                        <Container style={{ display: (step5 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Precios</p>
                                    <p className='m-0 pt-4'><Button onClick={downloadProductsFile}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Descargar</Button></p>
                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Stock</p>
                                    <p className='m-0 pt-4'><Button onClick={downloadStockFile}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Descargar</Button></p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>

                        </Container>



                    </Card>
                </Row>
            </Container>

            <Modal show={showLoading} centered backdrop="static" keyboard={false} onHide={handleCloseLoadingModal}>
                <Modal.Body style={{ textAlign: 'center' }}>

                    <Spinner className='mt-3' animation="border" />
                    <p className='mt-1 mb-3'>Procesando...</p>


                </Modal.Body>
            </Modal>
        </div>
    )
}
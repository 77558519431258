import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Badge, Button, Table, Dropdown, Form, Modal, ToggleButton, Alert } from "react-bootstrap";
import moment from 'moment'
import { params } from '../../../tools/constants'
import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout, getUserRoleParams } from '../../../controllers/user.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faSmile, faUser } from '@fortawesome/free-regular-svg-icons'
import { faBell, faCheck, faCoins, faDownload, faEllipsisV, faEye, faFileExport, faFileImport, faGavel, faIndustry, faPlusCircle, faStore, faTasks, faTrash, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import NavBar from "../navbar/Navbar";
import { processDate, processDateCalendar, processDateEvents, timeDue, timeSince, timeDueWithBadge, pnint } from '../../../tools/tools';
import { pn } from '../../../tools/chains';

export default function Home() {

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "4-Tech - Inicio";

    }, []);

    const test = () => {
        alert("ok")
    }


    return (
        <div className='p-0 mt-3' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <Container>
                {getUserRoleParams().canProviders == true ?
                    <Row className='pt-4'>
                        <Col xs={3}>
                            <Link to={'/importar'}>
                                <Card className={`statusCard blue`}>
                                    <Row className='align-center-vertically-v2 ps-2 pe-2'>
                                        <Col xs={3}>
                                            <FontAwesomeIcon icon={faFileImport} style={{ fontSize: 40 }} className='ps-2 pt-1'></FontAwesomeIcon>
                                        </Col>
                                        <Col>
                                            <Card.Title>Actualización de costos y stock</Card.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>

                        <Col xs={3}>
                            <Link to={'/proveedores'}>
                                <Card className={`statusCard blue`}>
                                    <Row className='align-center-vertically-v2 ps-2 pe-2'>
                                        <Col xs={3}>
                                            <FontAwesomeIcon icon={faIndustry} style={{ fontSize: 40 }} className='ps-2 pt-1'></FontAwesomeIcon>
                                        </Col>
                                        <Col>
                                            <Card.Title className='pt-2'>Proveedores</Card.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                    :
                    <div></div>
                }
                {getUserRoleParams().canCustomers == true ?
                    <Row className='pt-4'>
                        <Col xs={3}>
                            <Link to={'/exportar'}>
                                <Card className={`statusCard blue`}>
                                    <Row className='align-center-vertically-v2 ps-2 pe-2'>
                                        <Col xs={3}>
                                            <FontAwesomeIcon icon={faFileExport} style={{ fontSize: 40 }} className='ps-2 pt-1'></FontAwesomeIcon>
                                        </Col>
                                        <Col>
                                            <Card.Title>Actualizar listas de precios</Card.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>

                        <Col xs={3}>
                            <Link to={'/clientes'}>
                                <Card className={`statusCard blue`}>
                                    <Row className='align-center-vertically-v2 ps-2 pe-2'>
                                        <Col xs={3}>
                                            <FontAwesomeIcon icon={faStore} style={{ fontSize: 40 }} className='ps-2 pt-1'></FontAwesomeIcon>
                                        </Col>
                                        <Col>
                                            <Card.Title className='pt-2'>Clientes</Card.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                    :
                    <div></div>
                }
                {getUserRoleParams().canUsers == true ?
                    <Row className='pt-4'>
                        <Col xs={3}>
                            <Link to={'/usuarios'}>
                                <Card className={`statusCard blue`}>
                                    <Row className='align-center-vertically-v2 ps-2 pe-2'>
                                        <Col xs={3}>
                                            <FontAwesomeIcon icon={faUserAlt} style={{ fontSize: 40 }} className='ps-2 pt-1'></FontAwesomeIcon>
                                        </Col>
                                        <Col>
                                            <Card.Title className='pt-2'>Usuarios</Card.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>


                    </Row>
                    :
                    <div></div>
                }
            </Container>
        </div>
    )
}
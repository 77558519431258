import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Form, Dropdown, DropdownButton, Badge, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUsers, faEye, faUser, faSearch, faBusinessTime, faIndustry, faArrowLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { colors } from '../../../tools/constants';
import { pnint } from '../../../tools/tools';
import moment from 'moment/moment';

export default function Business(props) {

    const PAGE_TITLE = "Usuarios";
    const PAGE_ICON = faUser;

    const [users, setUsers] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `4-Tech - ${PAGE_TITLE}`;
        getUsers();
    }, []);

    const getUsers = async function () {
        const req = await get(`/users`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processUsers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processUsers = (data) => {
        let result = [];

        for (let p of data) {
            p.action = <Link to={`/usuarios/detalles/?id=${p.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon></Button></Link>
            result.push(p)
        }

        setUsers(result)
    }



    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />


            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={6} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={6} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                <Link to={'/inicio'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} /><span className='me-3'>Volver al menú</span>
                                </Link>

                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                            <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {window.location.href = "/usuarios/nuevo"}}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8, marginLeft: 2 }} />Nuevo usuario</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>




                        {users.length > 0 ?
                            <Container className='pt-3 mb-2'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_primary}`, color: 'white', borderRadius: 8 }}>
                                        <Col xs={8} className='align-center-vertically-v2'>
                                            <div>
                                                Nombre
                                            </div>
                                        </Col>
                                        <Col xs={3} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Rol
                                            </div>
                                        </Col>

                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {users.map((r, i) => (
                                    <div>

                                        <Row className='p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={8} className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0'>{r.name} {r.surname}</p>
                                                </div>
                                            </Col>

                                            <Col xs={3} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    <p className='m-0'>{r.Role.name}</p>
                                                </div>
                                            </Col>

                                            <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                <div style={{ textAlign: 'right' }}>
                                                    {r.action}
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron usuarios</p>
                                </Row>
                            </Container>

                        }



                    </Card>
                </Row>
            </Container>
        </div>
    )
}
import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight, faCheck, faCoins, faEye, faPlusCircle, faSchool, faSearch, faUser} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

import {get, post} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';


export default function NewUser(props){

    const PAGE_TITLE = "Nuevo usuario";
    const PAGE_ICON = faUser;

    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(0);
    const [selectedIsLawer, setSelectedIsLawer] = React.useState(false);

    const [showSearchCustomerModal, setShowSearchCustomerModal] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState({});

    const handleRole = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleIsLawer = (event) => {
        setSelectedIsLawer(event.target.value);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `4-Tech - ${PAGE_TITLE}`;

        getRoles()
    }, []);

    const getRoles = async function(){
        const req = await get('/users/roles', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setRoles(res);
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const createUser = async function(){
        const user = {
            roleId: document.getElementById('selectRole').value,
            name: document.getElementById('textName').value,
            surname: document.getElementById('textSurname').value,
            email: document.getElementById('textEmail').value,
            password: null,
            token: null,
            enabled: true,
            avatar: null
        }

        const req = await post('/users/signup', getUserToken(), user);
        const res = await req.json();

        if(req.status === 201){
            window.location.href = '/usuarios'
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Link to={`/usuarios`} className='link-primary'>
                                        <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />Volver a Usuarios
                                    </Link>
                                    
                                    
                                </td>
                                <td className='content-options rows right'>
                                    
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                        <Row>
                            <Col xs={6}>
                                <table className='w-100'>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold', width: 170}}>Nombre</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textName" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Apellido</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textSurname" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Rol</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Select className='cover content rows element' id="selectRole">

                                                    {roles.map((p, i) => (
                                                        <option key={i} id={i} value={p.id}>{p.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='pt-2' style={{fontWeight: 'bold'}}>Correo electrónico</td>
                                        <td className='pt-2'>
                                            <Form.Group>
                                                <Form.Control type="text" id="textEmail" placeholder="" />
                                            </Form.Group>
                                        </td>
                                    </tr>                     
                                </table>
                            </Col>
                            <Col xs={6}>
                            
                            </Col>
                            </Row>
                            <Form>

                                
                            </Form>

                            <div className='mt-5' style={{textAlign: 'right'}}>
                                <Button variant="success" onClick={createUser}><FontAwesomeIcon icon={faCheck} style={{marginRight: 8}} />Confirmar</Button>
                            </div>
                            
                        </Container>
                    </Card>
                </Row>
            </Container>

           
        </div>
    )
}